import { Box, Typography, Link, FormControl } from '@mui/material';
import { TooltipText } from '../inputComponents/TooltipText';
import { CheckboxComponent } from '../inputComponents/CheckboxComponent';
import { PrizesRatios } from './PrizesRatios';
import '../../pages/AssignPromotion/AssignPromotionPage.css';
import './GridItems.css';
import { prizeDataType } from '../../pages/EditPromotion/EditPromotionPage';

const alwaysWinLimitsRef = 'todo';

interface AlwaysWinProps {
    formik?: any;
    useTitles?: boolean;
    limitsBoxClass?: string;
    formClass?: string;
    startEndDateColumns?: number;
    timeZoneColumns?: number;
    containerSpacing?: number;
    prizesData: Array<prizeDataType>;
}

function AlwaysWinLimits({
    formik,
    useTitles = true,
    limitsBoxClass,
    formClass,
    prizesData,
}: AlwaysWinProps) {
    const handleCheckboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        formik.setFieldValue(e.target.name, checked);
    };

    const conditionMapping = {
        'alwaysWinLimits.ratioWinning': () => !prizesData.length,
    };

    const getDisabledCondition = (field: string) => {
        if (conditionMapping[field]) {
            return conditionMapping[field]();
        }
    };

    return (
        <Box data-testid={'alwaysWin-limits-box'} className='alwaysWin-limits-box'>
            {useTitles && (
                <>
                    <TooltipText
                        text='Always Win Limits'
                        textVariant='h4'
                        tooltip={
                            <Typography variant='body2'>
                                <Link
                                    className='tooltip-link'
                                    href={alwaysWinLimitsRef}
                                    target='_blank'
                                >
                                    Learn more
                                </Link>
                                about the always win limits.
                            </Typography>
                        }
                    />
                    <Typography className='typography-style' variant='body1'>
                        Specify the always win limits for this promotion
                    </Typography>
                </>
            )}
            <Box className={limitsBoxClass ? limitsBoxClass : 'limits-box'}>
                <FormControl className={formClass}>
                    <Box>
                        <CheckboxComponent
                            fieldName={'alwaysWinLimits.ratioWinning'}
                            label={
                                <TooltipText
                                    dataTestId='ratio-winning-tooltip'
                                    text={'Ratio Winning'}
                                    textVariant='body1'
                                    tooltip='The Ratio Winning limit is applicable only for active Always Win prizes. The sum of the Ratio Winning limit needs to be fulfilled to 100%. To activate this functionality, please create at least one prize.'
                                    styleName={`validity-checkbox-tooltip ${getDisabledCondition('alwaysWinLimits.ratioWinning')
                                        ? 'disabled'
                                        : ''
                                        }`}
                                />
                            }
                            disabledCondition={getDisabledCondition(
                                'alwaysWinLimits.ratioWinning'
                            )}
                            customOnChangeHandler={handleCheckboxOnChange}
                        />
                        <PrizesRatios formik={formik} />
                    </Box>
                </FormControl>
            </Box>
        </Box>
    );
}

export { AlwaysWinLimits };
