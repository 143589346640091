import * as Yup from 'yup';

export const referralRewardsValidationSchema = Yup.object().shape({
  referrerBox: Yup.boolean(),
  refereeBox: Yup.boolean(),
  referralRewards: Yup.object().shape({
    referrer: Yup.object()
      .default({})
      .shape({
        currency: Yup.string().test(
          'Referrer box Currency ID test',
          'Please select a currency Id to be awarded to the referrer',
          (value, testContext) => {
            const fromTestCtx: any = testContext;
            const currentState = fromTestCtx?.from[2]?.value;
            let result = true;
            if (currentState?.referrerBox && currentState?.referralRewards?.referrer && !currentState?.referralRewards?.referrer?.currency) {
                result = false;
            }
            return result;
          }
        ),
        amount: Yup.number()
          .test('Referrer Box Currency amount test', 'Please type in an amount between 1 and 9999 to be awarded to the referrer', (value, testContext) => {
            const fromTestCtx: any = testContext;
            const currentState = fromTestCtx?.from[2]?.value;
            let result = true;
            if (currentState?.referrerBox
                && currentState?.referralRewards?.referrer
                && (currentState?.referralRewards?.referrer?.amount === 0
                    || currentState?.referralRewards?.referrer?.amount > 9999
                    || !currentState?.referralRewards?.referrer?.amount)) {
              result = false;
            }
            return result;
          })
      }),
      referee: Yup.object()
      .default({})
      .shape({
        currency: Yup.string().test(
          'Referrer box Currency ID test',
          'Please select a currency Id to be awarded to the referee',
          (value, testContext) => {
            const fromTestCtx: any = testContext;
            let currentState = fromTestCtx?.from[2]?.value;
            let result = true;
            if (currentState?.refereeBox && currentState?.referralRewards?.referee && !currentState?.referralRewards?.referee?.currency) {
              result = false;
            }
            return result;
          }
        ),
        amount: Yup.number()
          .test('Referrer Box Currency amount test', 'Please type in an amount between 1 and 9999 to be awarded to the referee', (value, testContext) => {
            const fromTestCtx: any = testContext;
            let currentState = fromTestCtx?.from[2]?.value;
            let result = true;
            if (currentState?.refereeBox
                && currentState?.referralRewards?.referee
                && (currentState?.referralRewards?.referee?.amount === 0
                    || currentState?.referralRewards?.referee?.amount > 9999
                    || !currentState?.referralRewards?.referee?.amount)) {
              result = false;
            }
            return result;
          })
      })
  })
})
