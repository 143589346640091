import { InputComponent } from "../../components/inputComponents/InputComponent";
import { TooltipText } from "../../components/inputComponents/TooltipText";
import { SaveCampaign } from "../../components/createCampaingFlowComponent/SaveCampaign";
import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getMiscellaneousValuesTable } from '../../graphql/queries';
import { getIn, useFormikContext } from 'formik';
import { Auth } from 'aws-amplify';
import { Button, Box, Link } from '@mui/material'
import { experiencesList, promotionMarketList } from '../../constants/lists';
import { MultipleSelectOptions } from "../../components/selectComponents/MultipleSelectOptions";
import { AddToListModal } from "../../components/modals/AddToListModal";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { usePrompt } from '../../common/PromptOnChanges/promptHelper';
import { createCampaignState } from './CreateCampaignPage';
import { addNewExperienceValidationSchema } from '../CreateCampaign/campaignValidationSchema'
import {DateRangePickerWrapper} from '../../components/selectComponents/DateRangePicker'


interface CreateCampaignFormProps {
    step: number,
    updateStep: any,
    setCustomListsPresent: any,
    customListValues: string[],
    setUserEmailForId: any
}
const newExperienceSchema = addNewExperienceValidationSchema();

function CreateCampaignForm({ step, updateStep, setCustomListsPresent, customListValues, setUserEmailForId }: CreateCampaignFormProps): JSX.Element {
    const formik = useFormikContext<createCampaignState>();
    const { setFieldValue } = formik;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    type stepTypes = {
        [key: number]: string[]
    }
    const stepFields: stepTypes = {
        0: ["promotion_name", "promotion_fullName"],
        1: ["digital_experience"],
        2: ["promotion_region", "promotion_market"],
        3: ["promotion_dateRange"],
    }

    const validateAndMove = async () => {
        stepFields[step as keyof typeof stepFields].forEach(fieldName => {
            formik.setFieldTouched(fieldName, true);
        })
        const validation = await formik.validateForm();
        let result = true;
        stepFields[step as keyof typeof stepFields].forEach(fieldName => {
            if (validation[fieldName as keyof typeof validation]) {
                //validation did not pass
                result = false;
            }
        })
        if (result) {
            updateStep(1);
        }
    }

    const lowerCaseArray = (arrayToLowerCase: Array<string>) => {
        let lowerCasedArray: Array<string> = arrayToLowerCase.map(element => element.toLowerCase());
        return lowerCasedArray;
    }

    const getCustomLists = async (listId: string) => {
        let listData: any;
        try {
            listData = await API.graphql(graphqlOperation(getMiscellaneousValuesTable, { list_id: listId }));
        } catch (e: any) {
            console.error("Getting custom lists failed with: ", e.errors[0].message);
        }
        return listData
    }

    useEffect(() => {
        //function below is defined useEffect scope due to it only being used within the useEffect hook
        const appendCustomLists = (retrievedCustomList: any) => {
            if (retrievedCustomList) {
                setCustomListsPresent(true);
                retrievedCustomList.digital_experiences?.forEach((experience: string) => {
                    if (lowerCaseArray(experiencesList).indexOf(experience.toLowerCase()) === -1) {
                        experiencesList.push(experience)
                        customListValues.push(experience)
                    }
                })
            }
        }

        Auth.currentAuthenticatedUser()
            .then(userData => {
                const userEmail = userData?.attributes?.email;
                setFieldValue("promotion_owner", userEmail);
                setFieldValue("promotion_author", userEmail);
                setUserEmailForId(userEmail)
                getCustomLists(userEmail)
                    .then(listData => {
                        if (listData) {
                            appendCustomLists(listData.data.getMiscellaneousValuesTable.items[0])
                        }
                    }
                    );
            })
            .catch(() => console.log('Not signed in'));
    }, [customListValues, setCustomListsPresent, setFieldValue, setUserEmailForId]);

    const changesDetected = formik.dirty && formik.submitCount === 0;
    usePrompt(
        "This will discard all the changes. Are you sure?",
        changesDetected
    );

    return (
        <>
            {step === 0 &&
                <>
                    <TooltipText text='Campaign Name' textVariant='h4' tooltip='For example, FIFA 2022 Promo Germany.' />
                    <InputComponent
                        inputClassName={'textBox'}
                        inputFieldClass='campaign-name-input'
                        formik={formik}
                        inputValue={getIn(formik.values, 'promotion_name')}
                        formikLabel={'promotion_name'}
                        label='Campaign Name'
                        text='Note that a campaign is used as an umbrella item, meaning multiple promotions can be assigned to a single campaign. A good campaign name can include a marketing campaign name itself, product name associated with it, region, year and other relevant details. This way, you can keep the list of your campaigns organized, especially if there are multiple campaigns across your region.'
                    />
                    <InputComponent
                        inputClassName={'textBox'}
                        inputFieldClass='campaign-name-input'
                        formik={formik}
                        inputValue={getIn(formik.values, 'promotion_fullName')}
                        formikLabel={'promotion_fullName'}
                        label='Campaign Full Name'
                        text={
                            <>
                                You can validate the Campaign Full Name in the Data Dictionary Experience Tool which you can download here -
                                <Link className='campaign-name-input' href={'https://groups.coca-cola.com/sites/HarmonyHub'} target='_blank'>
                                    Harmony Hub - Home (coca-cola.com)
                                </Link>
                            </>
                        }
                    />
                </>
            }
            {step === 1 &&
                <MultipleSelectOptions
                    formik={formik}
                    title="Select Experience(s)"
                    subTitle="Select experience(s) that apply to this campaign"
                    listOfOptions={experiencesList}
                    formikFieldName="digital_experience"
                    inputLabel="Select Experience(s)*"
                    enableAdditional={true}
                    additionalLabel="+ Add New Experience"
                    handleOpen={handleOpen}
                    modalComponent={<AddToListModal formikState={formik} formikFieldName="digital_experience" value='option_name' schema={newExperienceSchema} header="+ Add New Experience" modalOpenState={open} listToInsertTo={experiencesList} handleClose={setOpen} customListValues={customListValues}/>}
                />
            }
            {step === 2 &&
                <MultipleSelectOptions
                    formik={formik}
                    title="Select Market(s)"
                    subTitle="Select market(s) suitable for your campaign"
                    listOfOptions={promotionMarketList}
                    formikFieldName="promotion_market"
                    inputLabel="Market(s)"
                />
            }
            {step === 3 &&
                <DateRangePickerWrapper
                    title="Allocate Dates"
                    description="Select a start and end date for the overall duration of the campaign"
                    formikDateRange={'promotion_dateRange'}
                    formikStartDate={'promotion_start_utc'}
                    formikEndDate={'promotion_end_utc'}
                />
            }
            {step === 4 &&
                <SaveCampaign formik={formik} />}
            {step < 4 &&
                <Box className="buttonWrapper" >
                    <Button className="stepperButton" variant="outlined" size="medium" startIcon={step === 0 ? undefined : <ArrowBackIos />} onClick={() => updateStep(-1)}>
                        {step === 0 && <>Cancel</>} {step !== 0 && <>Previous</>}
                    </Button>
                    {step === 3 && <Button className="stepperButton" variant="contained" size="medium" onClick={() => formik.submitForm()}> Complete </Button>}
                    {step !== 3 && <Button className="stepperButton" variant="contained" size="medium" endIcon={<ArrowForwardIos />} onClick={() => validateAndMove()}>
                        Next
                    </Button>}
                </Box>
            }
        </>
    )
}

export { CreateCampaignForm }
