import { API, graphqlOperation, Storage } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import awsconfig from '../../../aws-exports';

import { getCurrencyIdTransactionTable, getCurrencyTable } from '../../../graphql/queries';
import { updateCurrencyCurrencyTable, createCurrencyCurrencyTable } from '../../../graphql/mutations';
import {
    GetCurrencyTableResult,
    CurrencyTableItem,
    GetTransactionTableResult,
    GetTransactionTableItem,
} from '../../../types/componentTypes/CurrencyTypes';
import { getErrorMessageFromError, removeFalsyValuesFromCurrency } from '../helpers/currency-helpers';
import { ICreatePromotionState } from '../../../types/componentTypes/assignPromo';

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: '',
    },
});

export const updateCurrency = async (currency: CurrencyTableItem) => {
    return await API.graphql(
        graphqlOperation(updateCurrencyCurrencyTable, {
            input: currency,
        })
    );
};

export const createCurrency = async (currency: CurrencyTableItem) =>
    await API.graphql(
        graphqlOperation(createCurrencyCurrencyTable, {
            input: currency,
        })
    );

export const getCurrencies = async (returnOnlyActiveCurrs = false) => {
    const response = (await API.graphql(graphqlOperation(getCurrencyTable))) as GraphQLResult<GetCurrencyTableResult>;

    if (returnOnlyActiveCurrs)
        return (response.data?.getCurrencyTable.items as CurrencyTableItem[]).filter(
            (item) => item.is_active === null || item.is_active
        );

    return (response.data?.getCurrencyTable.items as CurrencyTableItem[]).filter(
        (item) => item.is_active || item.is_active === null
    );
};

export const getAndGroupCurrencies = async (currencies?: CurrencyTableItem[]) => {
    const items = currencies ? currencies : await getCurrencies();

    if (!items.length) {
        throw new Error('No data returned from the DB');
    }

    const groupedCurr = items.reduce((acc: { [key: string]: string[] }, item) => {
        const countryName: string = item.country || 'others';
        const currNames: string[] = acc[countryName];

        if (!currNames) {
            return { ...acc, [countryName]: [item.name.toLowerCase()] };
        }

        currNames.push(item.name.toLowerCase());
        return { ...acc, [countryName]: currNames };
    }, {});

    return groupedCurr;
};

export const getTransactionsForCurrencyId = async (currencyId: string) => {
    try {
        const response = (await API.graphql(
            graphqlOperation(getCurrencyIdTransactionTable, {
                currency_id: currencyId,
            })
        )) as GraphQLResult<GetTransactionTableResult>;

        return response.data?.getCurrencyIdTransactionTable.items as GetTransactionTableItem[];
    } catch (error) {
        console.log(error);
        throw new Error(getErrorMessageFromError(error));
    }
};

export const deleteIconFromS3 = async (fileName: string) => {
    const bucket = awsconfig.aws_user_files_s3_bucket.replace('private', 'public');
    try {
        return await Storage.remove(fileName, { bucket });
    } catch (error) {
        throw new Error((error as Error).message);
    }
};

export const removeIconFromCurrency = async (fileLocation: string, currency?: CurrencyTableItem) => {
    try {
        await deleteIconFromS3(fileLocation);
        if (!currency) return;
        await updateCurrency({
            ...removeFalsyValuesFromCurrency(currency),
            icon_url: '',
        });

        return;
    } catch (error) {
        console.error(error);
        throw new Error((error as Error).message);
    }
};

export const getActiveLinkedPromotionsForCurrency = async (currency: CurrencyTableItem) => {
    if (!currency.currency_assignment) return [];

    const parsedCurrAssignment: Record<string, string[]> = JSON.parse(currency.currency_assignment);

    return Object.values(parsedCurrAssignment)
        .flatMap((el) => el)
        .reduce<Promise<string[] | undefined>>(async (acc, configId) => {
            try {
                const fileName = `${configId}/conf.txt`;
                const response = await Storage.get(fileName, { download: true });

                if (!(response?.Body && response.Body instanceof Blob)) {
                    return acc;
                }

                const { configurationParameters } = (await new Response(response.Body).json()) as ICreatePromotionState;
                if (!configurationParameters) return acc;

                const { configurationStartUtc, configurationEndUtc } = configurationParameters;
                const now = new Date().getTime();

                const isPromotionActive = now > +configurationStartUtc && now < +configurationEndUtc;

                if (isPromotionActive) return [...((await acc) as string[]), configId];

                return acc;
            } catch (error) {
                console.log(error);
                throw new Error(getErrorMessageFromError(error));
            }
        }, Promise.resolve([]));
};
