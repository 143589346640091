import { Box, Grid, Typography } from '@mui/material';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { RadioButton } from '../../components/inputComponents/RadioButton';
import { useEffect, useState, Fragment } from 'react';
import { GridItems } from '../assignPromotionFlowComponent/GridItems';
import { getCurrenciesData, filterCurrenciesByConfigIds, getCurrenciesNames } from '../../utils/currenciesUtils';
import { PrizeTypeProps } from '../../types/componentTypes/prizeComponentTypes';
import { CurrencyTableItem } from '../../types/componentTypes/CurrencyTypes';
import DisableSelectedItemsDropdown from '../../common/DisableSelectedItemsDropdown/DisableSelectedItemsDropdown';

const tooltips: { [key: string]: string } = {
    auto_redeem:
        "With 'Collect & Get' option participants can decide when to use the accumulated currency. With 'Auto Collect & Get', once participants claim enough currency, they will be automatically awarded the prize. 'Auto Collect & Get' option is shown only if selected in the promotion profile",
    pool_prize:
        "If 'Instant Win' type is selected, this prize can be won only when a participant claims an available winning moment.If 'Always Win' type is selected, this prize will be added to the pool of guaranteed prizes.If 'Always Win' type isn't available, make sure either Instant Win (Winning Moments + Guaranteed Prize) or Instant Win (Always Win) option is selected in the promotion profile on Overview tab.",
};

function PrizeType({ formik, configurationData }: PrizeTypeProps) {
    const [radioGroup, setRadioGroup] = useState<string>('pool_prize');
    const [currencyList, setCurrencyList] = useState<string[]>([]);
    const [countryCurrencies, setCountryCurrencies] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([]);

    useEffect(() => {
        if (formik.values.tempParams.mechanic === 'iw') setRadioGroup('pool_prize');
        if (formik.values.tempParams.mechanic === 'c&g' || formik.values.tempParams.mechanic === 'autoRedeemCnG') setRadioGroup('auto_redeem');
    }, [formik.values.tempParams.mechanic]);

    useEffect(() => {
        if (radioGroup === 'auto_redeem') {
            getCurrenciesData()
                .then((data: CurrencyTableItem[]) => {
                    const configCurrencies = configurationData?.configurationParameters?.currencies || [];
                    const filteredCurrencies = filterCurrenciesByConfigIds(data, configCurrencies)

                    const currenciesList = getCurrenciesNames(filteredCurrencies);

                    setCountryCurrencies(filteredCurrencies);
                    setCurrencyList(currenciesList);

                    /**
                     * adding already selected currencies to the selected items as these should be
                     * removed from selection if the prize already contains these values
                     */

                    const preSelectedCurrs = formik.values.cost ? formik.values.cost.reduce(((acc: string[], curr) => {
                        if (!curr.name) return acc;

                        acc.push(curr.name)
                        return acc
                    }), []) : []
                    setSelectedCurrencies(preSelectedCurrs);
                });
        }
        //  Adding this lint exclude as the cost[] should not cause this effect to rerun.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radioGroup, configurationData?.configurationParameters?.country]);

    const addCurrencyParameter = (props: {
        event: React.SyntheticEvent<Element, Event>;
        selectedItem: string;
        currentItemIndex: number;
    }) => {
        const { selectedItem, currentItemIndex } = props;
        setSelectedCurrencies([...selectedCurrencies, selectedItem]);

        const ids = countryCurrencies
            .filter((currency: { name: string }) => currency.name === selectedItem)
            .map(({ currency_id }: { currency_id: string }) => currency_id);

        formik.setFieldValue(`cost[${currentItemIndex}]['currency_id']`, ids[0]);
        formik.setFieldValue(`cost[${currentItemIndex}]['name']`, selectedItem);
    };

    const addRow = () => {
        formik.setFieldValue('cost', [...formik.values.cost, { amount: '', currency_id: '', name: '' }]);
    };

    const removeRow = (params: { index: number }) => {
        formik.setFieldValue(
            'cost',
            formik.values.cost.filter((_, i: number) => i !== params.index)
        );

        const currencyForRemoval = formik.values.cost[params.index]?.name;
        setSelectedCurrencies([...selectedCurrencies].filter((curr) => curr !== currencyForRemoval));
    };

    const getDisplayValues = () => {
        const promotionFlow = configurationData?.flow;

        if (promotionFlow?.instantWin?.params?.alwaysWin) {
            return [
                { value: 'false', label: 'Instant Win' },
                { value: 'true', label: 'Always Win' },
            ];
        }

        if (promotionFlow?.autoRedeemCnG) {
            return [
                { value: 'false', label: 'Collect & Get' },
                { value: 'true', label: 'Auto Collect & Get' },
            ];
        }

        if (promotionFlow?.redeemPincodeForCurrencies) {
            return [{ value: 'false', label: 'Collect & Get' }];
        }

        return [{ value: 'false', label: 'Instant Win' }];
    };

    return (
        <Box className='prize-box-main'>
            <Typography variant='h4' gutterBottom>
                Prize Type
            </Typography>
            <TooltipText text='Select the applicable prize type' textVariant='body1' tooltip={tooltips[radioGroup]} />
            <Box className='radio-group-form'>
                <Typography variant='body1' className='prize-type-title'>
                    Prize Type
                </Typography>
                <RadioButton
                    formik={formik}
                    values={getDisplayValues()}
                    radioGroupName={radioGroup}
                />
            </Box>
            {radioGroup === 'auto_redeem' && (
                <Box className='radio-group-form'>
                    <Typography variant='body1' gutterBottom>
                        Cost
                    </Typography>
                    <Grid container className='gridMainContainer'>
                        {formik.values.cost.map(
                            (_: { amount: number | null; currency_id: string; name: string }, index: number) => (
                                <Fragment key={`prize-type-currency-selection-${index}`}>
                                    <GridItems
                                        formik={formik}
                                        index={index}
                                        maxFields={countryCurrencies.length || 5}
                                        testId={`prize-type-currency-dropdown-menu-${index}`}
                                        component={
                                            <DisableSelectedItemsDropdown
                                                inputComponentProps={{
                                                    uniqueKey: `prize-type-currency-input-${index}`,
                                                    label: 'Amount',
                                                    value: formik.values.cost[index].amount?.toString() || null,
                                                    formikLabel: `cost[${index}]['amount']`,
                                                    errorPath: 'cost',
                                                    type: 'number',
                                                    max: 9999
                                                }}
                                                dropDownProps={{
                                                    currentItemIndex: index,
                                                    label: 'Currency Name',
                                                    placeholder: 'Select A Currency',
                                                    value: selectedCurrencies[index] || null,
                                                    testId: `prize-type-currency-dropdown-option-${index}`,
                                                    uniqueKey: `prize-type-currency-dropdown-${index}`,
                                                    optionsList: currencyList,
                                                    selectedItems: selectedCurrencies,
                                                    textFieldStyles: { background: 'white' },
                                                    onChangeHandler: addCurrencyParameter,
                                                }}
                                                formik={formik}
                                            />
                                        }
                                        addAction={addRow}
                                        removeAction={removeRow}
                                        formikValueArray='cost'
                                        itemClass='currency-details'
                                    />
                                </Fragment>
                            )
                        )}
                    </Grid>
                </Box>
            )}
        </Box>
    );
}

export { PrizeType };
