import { Storage } from 'aws-amplify';
import { getIn, useFormikContext } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import {
    Button,
    Box,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Grid,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link as MuiLink } from '@mui/material';
import { AllocateDates } from '../../components/selectComponents/AllocateDates';
import { CurrencyDetails } from '../../components/assignPromotionFlowComponent/CurrencyDetails/CurrencyDetails.component';
import { InputComponent } from '../../components/inputComponents/InputComponent';
import { MixCodesDetails } from '../../components/assignPromotionFlowComponent/MixCodesDetails';
import { ParticipationLimits } from '../../components/assignPromotionFlowComponent/ParticipationLimits';
import { AdditionalPromoDetails } from '../../components/assignPromotionFlowComponent/AdditionalPromoDetails/AdditionalPromoDetails';
import { SavePromotion } from '../../components/assignPromotionFlowComponent/SavePromotion';
import { SelectMarketLanguage } from '../../components/assignPromotionFlowComponent/SelectMarketLanguage';
import { SelectOption } from '../../components/selectComponents/SelectOption';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { SwitchButton } from '../../components/inputComponents/SwitchComponent';
import { usePrompt } from '../../common/PromptOnChanges/promptHelper';
import {
    mechanicOptions,
    flowLabelMap,
    flowLambdas,
    languageCodes,
} from '../../constants/lists';
import moment from 'moment-timezone';
import { timezones } from '../../constants/timezones';
import { ParticipationSettings } from '../../components/assignPromotionFlowComponent/ParticipationSettings';
import { useEffect, useState } from 'react';
import {
    ConfigurationSubMechanics,
    PromoMechanic,
    PromoVariation,
} from '../../types/componentTypes/editPromotion';
import {
    AssignPromoFormProps,
    ICreatePromotionState,
} from '../../types/componentTypes/assignPromo';
import { commonLinks } from '../../constants/helpful-link';

const uniqid = require('uniqid');

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: '',
    },
});

const promoIdCharLength: number = 14;

type stepTypes = {
    [key: number]: string[];
};
const stepFields: stepTypes = {
    0: ['configurationParameters.additionalInformation.name'],
    1: ['configurationParameters.country', 'configurationParameters.language'],
    2: ['tempParams.startDateTime', 'tempParams.endDateTime'],
    3: ['tempParams.flowOption'],
    4: ['secrets.mixCodesParameters', 'params.lotIds', 'params.campaignIds'],
    5: ['params.reduceAmount', 'tempParams.currencyNames'],
    6: [
        'participationLimits.participationLimit',
        'participationLimits.participationLimitTime',
        'participationLimits.participationLifetimeLimit',
        'participationLimits.minAge',
        'participationLimits.participationLimitCalendarDatesRange.startDate',
        'participationLimits.participationLimitCalendarDatesRange.endDate',
        'participationLimits.participationLimitStartEndDatesRange.startDate',
        'participationLimits.participationLimitStartEndDatesRange.endDate',
        'allocationRules',
    ],
};

const subMechanicDefaults = {
    'Instant Win': 'Instant Win (Winning Moments)',
    'Collect & Get': 'Collect & Get',
};

function AssignPromotionForm({
    step,
    updateStep,
    campaignMarkets,
}: AssignPromoFormProps) {
    const formik = useFormikContext<ICreatePromotionState>();
    const [steps, setSteps] = useState([
        'Promotion Name',
        'Market & Language',
        'Timeline',
        'Promotion Mechanic',
        'MiX Codes Details',
        'Currency Details',
        'Participation Limits',
    ]);
    const [formHasErrors, setFormHasErrors] = useState(false);

    const { setFieldValue, errors } = formik;

    useEffect(() => {
        if (errors && Object.values(errors).length) {
            setFormHasErrors(true);
            return;
        }

        return setFormHasErrors(false);
    }, [errors]);

    const validateAndMove = async () => {
        stepFields[step].forEach((fieldName) => {
            formik.setFieldTouched(fieldName, true);
        });
        const validation = await formik.validateForm();
        let result = true;
        stepFields[step].forEach((fieldName) => {
            if (getIn(validation, fieldName)) {
                result = false;
            }
        });
        if (result) {
            updateStep(1);
        }
    };

    useEffect(() => {
        const mainSteps = [
            'Promotion Name',
            'Market & Language',
            'Timeline',
            'Promotion Mechanic',
            'MiX Codes Details',
            'Currency Details',
            'Participation Limits',
            'Additional Details',
        ];
        if (formik.values.tempParams.flowOption === 'Collect & Get') {
            const changedSteps = [...mainSteps];
            const stepIndex = changedSteps.indexOf('Participation Limits');
            changedSteps[stepIndex] = 'Participation settings';
            setSteps(changedSteps);
        } else {
            setSteps(mainSteps);
        }
    }, [formik.values.tempParams.flowOption]);

    const setFlow = (e: any, params: { option: string }) => {
        const selectedMechanic = params.option;
        const flow = flowLabelMap[selectedMechanic] || '';
        setFieldValue('flowLambdas', flowLambdas[flow]);
        setFieldValue('flow', flow);
        const subMechanic =
            subMechanicDefaults[
            selectedMechanic as keyof typeof subMechanicDefaults
            ] || '';
        setFieldValue(
            'configurationParameters.configurationSubMechanic',
            subMechanic
        );
    };

    const checkForAWFlag = (
        e: React.MouseEvent<HTMLInputElement>,
        params: { option: string }
    ) => {
        if (params.option === PromoVariation.InstantWinWM) {
            setFieldValue('params.alwaysWin', false);
            setFieldValue('params.algorithm', 'winningMoments');
        }

        if (params.option === PromoVariation.InstantWinAW) {
            setFieldValue('params.alwaysWin', true);
            setFieldValue('params.algorithm', 'winningMoments');
        }

        if (params.option === PromoVariation.InstantWinWMGP) {
            setFieldValue('params.alwaysWin', true);
            setFieldValue('params.algorithm', 'winningMoments');
        }
    };

    const checkAutoCG = (toggleState: boolean) => {
        const { flowLambdas } = formik.values;
        if (toggleState && !flowLambdas.includes('autoRedeemPrizeLambda')) {
            setFieldValue(
                'configurationParameters.configurationSubMechanic',
                ConfigurationSubMechanics.AutoCollectAndGet
            );
            setFieldValue('flowLambdas', [
                ...formik.values.flowLambdas,
                'autoRedeemPrizeLambda',
            ]);
            setFieldValue('flow', 'autoRedeemCnG');
        }

        if (!toggleState && flowLambdas.includes('autoRedeemPrizeLambda')) {
            setFieldValue(
                'configurationParameters.configurationSubMechanic',
                ConfigurationSubMechanics.CollectAndGet
            );
            setFieldValue(
                'flowLambdas',
                formik.values.flowLambdas.filter(
                    (v: string) => v !== 'autoRedeemPrizeLambda'
                )
            );
            setFieldValue('flow', 'redeemPincodeForCurrencies');
        }
    };

    const convertDatesToTimestamp = (dateFields: Array<string>) => {
        dateFields.forEach((field) => {
            const date = getIn(formik.values, field);
            if (date && !Number.isInteger(date)) {
                setFieldValue(field, moment(date).valueOf(), false);
            }
        });
    };

    const mapTimeZones = (tzFields: Array<string>) => {
        tzFields.forEach((field) => {
            const tz = getIn(formik.values, field);
            if (tz) {
                setFieldValue(
                    field,
                    Object.keys(timezones).find((key) => timezones[key] === tz) ||
                    'Europe/London',
                    false
                );
            }
        });
    };

    const handleParticipationLimits = () => {
        const { minAge, calendarDay, rolling, lifetime } =
            formik.values.tempParams.limits;
        const limits = formik.values.participationLimits;

        if (!minAge) {
            delete limits.minAge;
        }

        if (!calendarDay) {
            delete limits.participationLimitCalendarDatesRange;
            delete limits.participationLimitCalendarDatesLimit;
            delete limits.participationLimitCalendarDatesTimeZone;
        }

        if (!rolling) {
            delete limits.participationLimit;
            delete limits.participationLimitTime;
            delete limits.participationLimitTimeZone;
            delete limits.participationLimitStartEndDatesRange;
        }

        if (!lifetime) {
            delete limits.participationLifetimeLimit;
        }

        if (calendarDay || rolling || lifetime) {
            formik.values.checkerLambdas.push('participationLimit');
        }

        if (minAge) {
            formik.values.checkerLambdas.push('age');
        }

        const useDatesLimit = Object.keys(formik.values.tempParams.limits).filter(
            (key) => {
                if (key !== 'minAge' && formik.values.tempParams.limits[key]) {
                    return true;
                }
                return false;
            }
        ).length;

        if (useDatesLimit) {
            setFieldValue(
                'participationLimits.participationLimitMultipleChecks',
                true
            );
            convertDatesToTimestamp([
                'participationLimits.participationLimitStartEndDatesRange.startDate',
                'participationLimits.participationLimitStartEndDatesRange.endDate',
                'participationLimits.participationLimitCalendarDatesRange.startDate',
                'participationLimits.participationLimitCalendarDatesRange.endDate',
            ]);
        } else {
            delete limits.participationLimitMultipleChecks;
        }
    };

    const transformLanguageCode = () => {
        const language = getIn(formik.values, 'configurationParameters.language');
        const code = Object.keys(languageCodes).find(
            (key) => languageCodes[key] === language
        );
        setFieldValue('configurationParameters.language', code);
    };

    const handleSubmitChanges = () => {
        setFieldValue(
            'configurationId',
            (uniqid() + uniqid()).substring(0, promoIdCharLength)
        );
        handleParticipationLimits();
        transformLanguageCode();
        convertDatesToTimestamp([
            'tempParams.startDateTime',
            'tempParams.endDateTime',
        ]);
        mapTimeZones([
            'configurationParameters.configurationDatesTimezone',
            'participationLimits.participationLimitTimeZone',
            'participationLimits.participationLimitCalendarDatesTimeZone',
        ]);
        formik.submitForm();
    };

    const location = useLocation();
    const promotionId = location.pathname.replace(/^.*\/(.*)$/, '$1');

    const changesDetected = formik.dirty && formik.submitCount === 0;
    usePrompt(
        'This will discard all the changes. Are you sure?',
        changesDetected
    );

    return (
        <>
            {step === 0 && (
                <>
                    <TooltipText
                        text='Promotion Name'
                        textVariant='h4'
                        tooltip='For example, FIFA 2022 Promo Germany.'
                    />
                    <InputComponent
                        inputClassName='text-box'
                        formik={formik}
                        inputValue={getIn(
                            formik.values,
                            'configurationParameters.additionalInformation.name'
                        )}
                        formikLabel={'configurationParameters.additionalInformation.name'}
                        label='Promotion Name'
                        text='A unique name gives an identity to your promotion and helps to differentiate it from other promotions that are relevant to the campaign. Once this promotion is created, a unique ID will be automatically assigned to it. You can see the created promotion and its ID under the campaign profile.'
                    />
                </>
            )}
            {step === 1 && (
                <SelectMarketLanguage formik={formik} markets={campaignMarkets} />
            )}
            {step === 2 && (
                <>
                    <TooltipText
                        text='Promotion Dates'
                        textVariant='h4'
                        tooltip='The dates were automatically inherited from the timeline selected for the campaign. If the timeline is different for this promotion, make sure to select the applicable start and end date here.'
                    />
                    <AllocateDates
                        description='Select a start and end date for the overall duration of the promotion'
                        datetime={true}
                        formikStartDate={'tempParams.startDateTime'}
                        formikEndDate={'tempParams.endDateTime'}
                        maxEndDate={
                            getIn(formik.values, 'configurationParameters.configurationEndUtc')
                        }
                        formikTimezoneValue={
                            'configurationParameters.configurationDatesTimezone'
                        }
                        datesClass='date-box'
                        containerSpacing={2}
                        startEndDateColumns={6}
                        timeZoneColumns={12}
                    />
                </>
            )}
            {step === 3 && (
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                >
                    <TooltipText
                        text='Promotion Mechanic'
                        textVariant='h4'
                        tooltip={
                            <Typography variant='body2'>
                                <MuiLink
                                    className='tooltip-link'
                                    href={commonLinks.PROMO_MECHANICS}
                                    target='_blank'
                                >
                                    Learn more
                                </MuiLink>{' '}
                                about NGPS promotion mechanics.
                            </Typography>
                        }
                    />

                    <SelectOption
                        selectClass='text-box'
                        formik={formik}
                        formikValue={'tempParams.flowOption'}
                        optionsList={mechanicOptions}
                        description='Select a suitable promotion mechanic'
                        descriptionVariant='body1'
                        inputLabel={'Promo Mechanic'}
                        customAction={setFlow}
                    />

                    {formik.values.tempParams.flowOption === PromoMechanic.InstantWin && (
                        <Grid item xs={12}>
                            <SelectOption
                                selectClass='text-box'
                                formik={formik}
                                formikValue={'configurationParameters.configurationSubMechanic'}
                                optionsList={[
                                    'Instant Win (Winning Moments)',
                                    'Instant Win (Winning Moments + Guaranteed Prize)',
                                    'Instant Win (Always Win)',
                                ]}
                                inputLabel={'Select Variation'}
                                customAction={checkForAWFlag}
                            />
                        </Grid>
                    )}

                    {formik.values.tempParams.flowOption ===
                        PromoMechanic.CollectAndGet && (
                            <Grid item xs={10}>
                                <SwitchButton
                                    formik={formik}
                                    formikValue={'tempParams.autoRedeemCnG'}
                                    label={'Enable Auto Collect & Get'}
                                    formStyle={'switch-form'}
                                    customAction={checkAutoCG}
                                />
                            </Grid>
                        )}
                </Grid>
            )}
            {step === 4 && <MixCodesDetails formik={formik} />}
            {step === 5 && <CurrencyDetails formik={formik} />}
            {step === 6 &&
                (formik.values.tempParams.flowOption === 'Instant Win' ||
                    formik.values.tempParams.flowOption === 'Prize Draw (Lottery)') && (
                    <ParticipationLimits formik={formik} />
                )}
            {step === 6 &&
                formik.values.tempParams.flowOption === 'Collect & Get' && (
                    <ParticipationSettings formik={formik} />
                )}
            {step === 7 && <AdditionalPromoDetails formik={formik} />}
            {step === 8 && <SavePromotion formik={formik} />}
            <Box className='buttons' sx={{ mt: 5 }}>
                {step <= 0 && (
                    <Button
                        variant='outlined'
                        component={Link}
                        to={`/editCampaign/${promotionId}`}
                    >
                        Cancel
                    </Button>
                )}
                {step > 0 && step <= 7 && (
                    <Button
                        color='secondary'
                        variant='contained'
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => updateStep(-1)}
                    >
                        Previous
                    </Button>
                )}
                {step === 7 && (
                    <Button
                        disabled={formHasErrors}
                        variant='contained'
                        onClick={handleSubmitChanges}
                    >
                        Complete
                    </Button>
                )}
                {step < 7 && (
                    <Button
                        variant='contained'
                        name='Next'
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={() => validateAndMove()}
                    >
                        Next
                    </Button>
                )}
            </Box>
            <Stepper
                activeStep={step}
                alternativeLabel
                sx={{ width: 800, mx: 'auto', mt: 10 }}
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </>
    );
}

export { AssignPromotionForm };
