import { Box, Typography, Link } from "@mui/material";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { Form, Formik } from 'formik';
import { LimitsForm } from './LimitsForm';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { limitsValidationSchema } from './LimitsValidationSchema';
import { flowLabelMap } from './../../../constants/lists'
import {
    LimitsProps,
    PrizeRatioType,
    limitsStateValues,
} from '../../../types/componentTypes/limits';
import '../CommonStyles.css';
import './Limits.css';
import { saveConfig } from '../../../utils/s3FileUtils';
import { clearParticipationLimits, clearAlwaysWinLimits, getCheckerLambdasFromTempParams, getIWLimitsForConfig, shouldDeleteBlockEntry } from "./helpers/limit-helpers";
import useLimitsInitialState from "./hooks/useLimitsInitialState";
import { API } from "aws-amplify";
import { updatePrizeCatalogueTable } from "../../../graphql/mutations";
import { prizeDataType } from "../EditPromotionPage";
import { PrizeTableItem } from "../../../types/componentTypes/PrizeTypes";
import { helpfulLinks } from "./constants/limit-constants";
import GenericSpinner from '../../../common/Spinners/GenericSpinner';

const Limits = ({
    promotionMechanic,
    config,
    setConfig,
    setNotificationState,
    handleTabChange,
    prizesData,
    setPrizeData
}: LimitsProps) => {
    const urlParams = useParams();

    const [promoFlow, setPromoFlow] = useState<string>('')
    const [prizes, setPrizes] = useState<Array<prizeDataType>>(prizesData);
    const { initialState } = useLimitsInitialState(config, prizes);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    useEffect(() => {
        setPromoFlow(flowLabelMap[promotionMechanic])
    }, [promoFlow, promotionMechanic])

    useEffect(() => {
        setPrizes(prizesData);
    }, [prizesData]);

    interface UpdatePrizeResult {
        updatePrizeCatalogueTable: {
            items?: PrizeTableItem[];
        };
    }

    const updatePrize = async (prize: PrizeRatioType) => {
        const response = await API.graphql({
            query: updatePrizeCatalogueTable, variables: {
                input: {
                    configuration_id: config.configurationId,
                    prize_id: prize.prizeId,
                    winning_ratio: Number(prize.ratio) * 100,
                }
            }
        }) as GraphQLResult<UpdatePrizeResult>;

        return response.data?.updatePrizeCatalogueTable;
    };

    const handleAlwaysWinPrizeRatios = async (values: limitsStateValues) => {
        try {
            let prizesToUpdate = {};
            let updateResults = [];

            if (values.alwaysWinLimits.ratioWinning) {
                // preparing ratios winning object for selected prizes
                values.alwaysWinLimits.alwaysWinPrizeRatios.forEach((item: PrizeRatioType) => {
                    const [, id] = item.prizeId.split('/');
                    if (!id) { throw new Error('Prize id not found') }
                    Object.assign(prizesToUpdate, { [id.trim()]: item.ratio });
                });

                // reset winning ratios to 0 for non-selected prizes
                prizes.forEach((item: prizeDataType) => {
                    if (!prizesToUpdate[item.prize_id]) {
                        Object.assign(prizesToUpdate, { [item.prize_id]: 0 });
                    }
                });
            } else {
                // delete winning ratio all prizes when ratio winning is disabled
                prizes.forEach(async (item: prizeDataType) => {
                    Object.assign(prizesToUpdate, { [item.prize_id]: 0 });
                });
            }

            // update winning ratios to prize catalogue table
            if (Object.keys(prizesToUpdate).length !== 0) {
                for (const [prizeId, ratio] of Object.entries(prizesToUpdate)) {
                    const response = await updatePrize({ prizeId, ratio } as PrizeRatioType);
                    updateResults.push(response);
                }
            }

            setPrizeData(updateResults);
        } catch (e) {
            setNotificationState({
                open: true,
                title: 'Error',
                content: 'Prize winning ratio update failed.',
                level: "error"
            })
            return Promise.reject(new Error(
                `Failed to update winning ratio for prize: ${e}`));
        }
    }

    const handleSubmit = (values: limitsStateValues) => {
        if (shouldDeleteBlockEntry(
            initialState.participationLimits,
            values.participationLimits,
            initialState.tempParams?.limits,
            values.tempParams?.limits
        )) {
            values.flow[promoFlow].params.partLimitVer =
                values.flow[promoFlow]?.params?.partLimitVer ?
                    values.flow[promoFlow].params.partLimitVer + 1 : 1
        }
        let updatedParticipationLimitsValues = clearParticipationLimits(values, promoFlow);

        let updatedLimitsValues = clearAlwaysWinLimits(updatedParticipationLimitsValues, promoFlow);
        updatedLimitsValues = {
            ...updatedLimitsValues,
            flow: {
                ...updatedLimitsValues.flow,
                [promoFlow]: {
                    ...updatedLimitsValues.flow[promoFlow],
                    checkerLambdas: getCheckerLambdasFromTempParams(updatedLimitsValues, updatedLimitsValues.flow[promoFlow]?.checkerLambdas),
                    params: {
                        ...updatedLimitsValues.flow[promoFlow]?.params,
                        ...updatedLimitsValues.participationLimits,
                        ...updatedLimitsValues.alwaysWinLimits,
                        ...(promoFlow !== 'promoEntry' && getIWLimitsForConfig(updatedLimitsValues))
                    },
                },
            },
        };
        updatedLimitsValues.tempParams?.limits?.minAge ?
        (updatedLimitsValues.configurationParameters.additionalInformation.minAge = updatedLimitsValues.participationLimits?.minAge) :
        (delete updatedLimitsValues.configurationParameters.additionalInformation.minAge)
        delete updatedLimitsValues.tempParams;
        delete updatedLimitsValues.participationLimits;
        delete updatedLimitsValues.alwaysWinLimits;
        delete updatedLimitsValues.selectedDateLimitOption;
        return updatedLimitsValues;
    }

    return (
        <>
            <GenericSpinner showSpinner={showLoadingSpinner} />
            <Box className='edit-page-wrapper'>
                <Typography variant='h3'>
                    Limits
                </Typography>
            </Box>
            <Formik
                initialValues={initialState}
                validationSchema={limitsValidationSchema}
                enableReinitialize
                onSubmit={async (values) => {
                    try {
                        setShowLoadingSpinner(true);
                        if (values.alwaysWinLimits.hasOwnProperty('ratioWinning')) {
                            await handleAlwaysWinPrizeRatios(values);
                        };
                        const modifiedConfig = handleSubmit(values);
                        setConfig(modifiedConfig)
                        await saveConfig({ urlParams, submitData: modifiedConfig, setNotificationState });
                        setShowLoadingSpinner(false);
                        setNotificationState({
                            open: true,
                            title: 'Promotion updated successfully',
                            content: 'Promotion Changes were saved!',
                            level: "success"
                        })
                    } catch (e) {
                        console.error(e);
                    }
                }}
            >
                <Form>
                    <LimitsForm handleTabChange={handleTabChange} prizesData={prizesData} />
                </Form>
            </Formik >
            <Typography variant="h3">Helpful Links</Typography>
            <Box className='helpfulLinks'>
                {helpfulLinks[promotionMechanic].map((helpfulLink, i) =>
                    <Link key={i} target='_blank' href={helpfulLink.link} rel="noopener noreferrer">{helpfulLink.text}</Link>
                )}
            </Box>
        </>
    )
}

export { Limits };
