import { Grid, Pagination, Typography, Container, Box } from '@mui/material';
import { CampaignBox } from '../../components/listCampaignsComponent/CampaignBox';
import { StyledEngineProvider } from '@mui/material/styles';
import { useState, useEffect, ChangeEvent } from 'react';
import { Campaign } from '../../types/campaign';
import './GridWithPagination.css';

export interface GridData {
    gridData: Array<Campaign>;
}
export default function GridWithPagination({
    gridData,
}: GridData): JSX.Element {
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [finalDataToDisplay, setFinalDataToDisplay] = useState([]);
    const itemsPerPage = 6;

    useEffect(() => {
        const byDate = (
            a: { entry_date: string | number | Date },
            b: { entry_date: string | number | Date }
        ) => {
            return (
                new Date(a.entry_date).valueOf() - new Date(b.entry_date).valueOf()
            );
        };

        const chunkDataForPages = (dataToChunk: Campaign[]) => {
            const chunks = [];
            const sortedData = [...dataToChunk];
            while (sortedData.length) {
                chunks.push(sortedData.splice(0, itemsPerPage));
            }
            return chunks;
        };

        const sortedDataByDate = gridData.sort(byDate).reverse();
        const dataToDisplay: Array<Campaign[]> =
            chunkDataForPages(sortedDataByDate);
        setFinalDataToDisplay(dataToDisplay);
        setCurrentPageNumber(1);
    }, [gridData]);

    const handlePageChange = (
        event: ChangeEvent<unknown>,
        page: number
    ): void => {
        setCurrentPageNumber(page);
    };
    return (
        <StyledEngineProvider injectFirst>
            <Container
                maxWidth='lg'
                background-color='white'
                className='containerStyles'
            >
                {gridData.length > 0 && (
                    <Box className='boxGridItems'>
                        <Grid
                            container
                            columnSpacing={3}
                            rowSpacing={2}
                            className='gridStyles'
                        >
                            {finalDataToDisplay[currentPageNumber - 1]?.map(
                                (element: any, boxIndex: any) => (
                                    <Grid
                                        key={`key ${boxIndex}`}
                                        item
                                        xs={6}
                                        data-testid={`campaign-card-${boxIndex}`}
                                    >
                                        <CampaignBox
                                            boxData={finalDataToDisplay[currentPageNumber - 1]}
                                            boxIndex={boxIndex}
                                        />
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>
                )}
                <Box>
                    <Typography className='totalItemsLabelStyles'>
                        {finalDataToDisplay.length !== 0
                            ? `${(finalDataToDisplay.length - 1) * itemsPerPage +
                            finalDataToDisplay[finalDataToDisplay.length - 1]?.length
                            } Total Items`
                            : `0 Total Items`}
                    </Typography>
                </Box>
                {finalDataToDisplay.length > 1 && (
                    <Pagination
                        className='paginationBarStyles'
                        count={finalDataToDisplay.length}
                        showFirstButton={true}
                        showLastButton={true}
                        siblingCount={0}
                        boundaryCount={1}
                        onChange={handlePageChange}
                    />
                )}
            </Container>
        </StyledEngineProvider>
    );
}
export { GridWithPagination };
