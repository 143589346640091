import {
    InputAdornment,
    IconButton,
    Box,
    Typography,
    FormHelperText,
} from '@mui/material';
import { useField } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './InputNumberSpinner.css';
import { useEffect } from 'react';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

interface SpinnerProps {
    fieldName: string;
    label?: string;
    incrementValue: number;
    decrementValue?: number; // if not provided the same as increment will be used
    customFunc?: (event: unknown) => void;
    customClass?: string;
    minValue?: number;
    maxValue?: number;
    fullWidth?: boolean;
    inputClass?: string;
    spinnerName?: string;
}

function InputNumberSpinner({
    fieldName,
    label,
    incrementValue,
    decrementValue,
    customFunc,
    customClass,
    inputClass,
    minValue,
    maxValue,
    spinnerName,
    fullWidth = false,
}: SpinnerProps) {
    const [field, meta, helpers] = useField(fieldName);
    const { setValue } = helpers;

    const disabledCount =
        (minValue && field.value <= minValue) || field.value === 0;
    const disabledIncrement = maxValue ? field.value >= maxValue : false;
    const handleIncrement = () => {
        const current = parseInt(field.value) || minValue || 0;
        setValue(current + incrementValue);
    };
    const handleDecrement = () => {
        setValue(field.value - (decrementValue ? decrementValue : incrementValue));
    };

    const defaultValue =
        parseInt(field.value) || parseInt(meta.initialValue) || minValue || 0;
    useEffect(() => {
        setValue(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Typography variant='body2'>{label}</Typography>
            <Box>
                <TextFieldWrapper
                    name={spinnerName}
                    className={customClass || ''}
                    value={defaultValue}
                    onChange={(event) =>
                        setValue(
                            Number(event.target.value) ||
                            (minValue ? minValue : Number(event.target.value))
                        )
                    }
                    onBlur={customFunc || undefined}
                    fullWidth={fullWidth}
                    variant='outlined'
                    InputProps={{
                        className: inputClass || 'spinner-box',
                        inputProps: {
                            ...{ 'data-testid': 'number-spinner' },
                            style: { textAlign: 'center' },
                        },
                        startAdornment: (
                            <InputAdornment position='start'>
                                <IconButton
                                    className='adornment-input-start'
                                    disabled={disabledCount}
                                    onClick={handleDecrement}
                                >
                                    <RemoveIcon
                                        className={!disabledCount ? 'spinner-icon' : ''}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    data-testId='plus-icon'
                                    className='adornment-input-end'
                                    disabled={disabledIncrement}
                                    onClick={handleIncrement}
                                    onBlur={customFunc || undefined}
                                >
                                    <AddIcon
                                        data-testId='increase-button'
                                        className={!disabledIncrement ? 'spinner-icon' : ''}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormHelperText error={true}>
                    {meta.touched && meta.error}
                </FormHelperText>
            </Box>
        </Box>
    );
}

export { InputNumberSpinner };
