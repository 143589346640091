import { ConfigType } from '../../../../types/configuration';

export interface friendReferraType {
    config: ConfigType,

}

export const boxTypeMapNames = {
    referrer: 'Referrer',
    referee: 'Referee'
}

export interface friendReferraPropsType {
    config: ConfigType
}

export interface RefProperities {
    amount: number,
    currency: string,
    sendEmail: boolean,
    limit: number
}

export interface referralFormPropType {
    referrerBox: boolean,
    refereeBox: boolean,
    referralRewards: {
        referrer: RefProperities,
        referee: RefProperities
    }
}
