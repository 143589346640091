import { Icon, Tooltip, Grid, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TooltipProps, ToolTipPlacement } from '../../types/componentTypes/tooltip';
import './TooltipText.css';

export function TooltipText(props: TooltipProps) {
    const { text, tooltip, textVariant, iconSize, styleName, placement, dataTestId = 'tool-tip-text', textClassName } = props;

    return (
        <Grid container className={styleName ? styleName : 'tooltipGrid'}>
            <Typography data-testid={`${dataTestId}-title`} variant={textVariant} className={textClassName}>
                {text}
            </Typography>
            <Tooltip
                arrow
                data-testid={`${dataTestId}-tooltip`}
                sx={{ marginTop: '-2px' }}
                classes={{ tooltip: 'tooltip_text' }}
                title={tooltip}
                placement={placement || ToolTipPlacement.Bottom}>
                <Icon
                    data-testid={`${dataTestId}-icon`}
                    className='icon-style'
                    fontSize={iconSize ? iconSize : 'large'}>
                    <HelpOutlineIcon />
                </Icon>
            </Tooltip>
        </Grid>
    );
}

export default TooltipText;
