import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    Tooltip,
    TableBody,
    Button,
    TableCell,
    Skeleton,
    TablePagination,
    CircularProgress,
    TableFooter,
} from '@mui/material';
import CopyIcon from '@mui/icons-material/CopyAll';
import InfoIcon from '@mui/icons-material/Info';
import { Notification } from '../../common/Notification/Notification';
import {
    useAssignedPromotionsList,
    useFilteredList,
    useGetPrizesInfo,
    usePaginatedData,
} from './hooks/assignedPromotionsHooks';
import { listAssignedPromosPropsType } from '../../types/componentTypes/assignedPromotions';
import { defaultNotifState } from '../../constants/currency-constants';

function ListAssignedPromotions(listAssignedPromosProps: listAssignedPromosPropsType) {
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const { loading, s3PromotionsList } = useAssignedPromotionsList({ listAssignedPromosProps, setNotificationState })
    const { configPrizes, prizeLoading } = useGetPrizesInfo({ listAssignedPromosProps, setNotificationState });
    const assignedPromotionsList = useFilteredList(s3PromotionsList, listAssignedPromosProps);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const paginatedPromoList = usePaginatedData(assignedPromotionsList, rowsPerPage, page, setPage)

    const navigate = useNavigate();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    function PromotionName({ data, mechanic }: any) {
        if (mechanic === 'Collect And Get') {
            return (
                <div className="promotion-name">
                    <div className="circle">CG</div>
                    <span>{data}</span>
                </div>
            );
        } else {
            return (
                <div className="promotion-name">
                    <div className="circle">IW</div>
                    <span>{data}</span>
                </div>
            );
        }
    }

    const copyPromoId = (value: string) => {
        navigator.clipboard.writeText(value);
        setNotificationState({
            open: true,
            title: 'Promotion ID copied to clipboard',
            content: '',
            level: 'success',
        });
    };

    return (
        <>
            <Notification
                notificationState={notificationState}
                setNotificationState={setNotificationState}
            />
            <TableContainer component={Paper} className={'assign-promo-list-table'}>
                <Table className="tableClass" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="rowCells">Promotion Name</TableCell>
                            <TableCell className="rowCells" align="left">
                                Promotion ID
                            </TableCell>
                            <TableCell className="rowCells" align="left">
                                Market
                            </TableCell>
                            <TableCell className="rowCells" align="left">
                                Start Date
                            </TableCell>
                            <TableCell className="rowCells" align="left">
                                End Date
                            </TableCell>
                            <TableCell className="rowCells" align="left">
                                Mechanic
                            </TableCell>
                            <TableCell
                                className="rowCells row-item-assign-promo"
                                align="left"
                            >
                                Prizes Added
                                <Tooltip
                                    title={
                                        <span>
                                            Information about whether the prizes are added or not for
                                            the specific promotion.
                                        </span>
                                    }
                                >
                                    <InfoIcon fontSize="small" />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        {loading &&
                            Array(4)
                                .fill(1)
                                .map((el, i) => (
                                    <TableRow key={i}>
                                        {Array(8)
                                            .fill(1)
                                            .map((el, i) => (
                                                <TableCell key={i}>
                                                    <Skeleton />
                                                    <Skeleton width="60%" />
                                                </TableCell>
                                            ))}
                                    </TableRow>
                                ))}
                        {!loading && assignedPromotionsList.length === 0 && (
                            <TableRow className="borderStyleFirstElement">
                                <TableCell
                                    align="left"
                                    colSpan={7}
                                    component="th"
                                    scope="row"
                                    className="tableFirstCell"
                                >
                                    + NEW PROMOTION
                                </TableCell>
                                <TableCell align="left" className="tableLastCell">
                                    <Button
                                        component={RouterLink}
                                        to={`/assignPromotion/${listAssignedPromosProps.data.promotion_id}`}
                                        variant="outlined"
                                        className={`buttonManageCreate ${listAssignedPromosProps.createDisabled ? 'disabled' : ''}`}
                                        disabled={listAssignedPromosProps.createDisabled}
                                    >
                                        Add
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableHead>
                    {!loading && paginatedPromoList.length > 0 && (
                        <TableBody>
                            {paginatedPromoList[page].map(
                                (assignedPromotionsList: any, i: number) => {
                                    const prizesAvailable =
                                        configPrizes.filter(
                                            (configPrizeObject) =>
                                                configPrizeObject.configurationId ===
                                                assignedPromotionsList.promotionConfigurationId
                                        )[0]?.prizesArray?.length > 0
                                            ? true
                                            : false;
                                    const toEditPromotion = () => {
                                        navigate(
                                            `/editPromotion/${assignedPromotionsList.promotionConfigurationId}`
                                        );
                                    };
                                    return (
                                        <TableRow key={i} className={'borderStyleSecondElements'}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                className="tableFirstCellDisplayData"
                                            >
                                                <PromotionName
                                                    data={assignedPromotionsList.promotionName}
                                                    mechanic={assignedPromotionsList.mechanic}
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableMiddleCellDisplayData"
                                            >
                                                <div className="copy-icon-button-assign-promo">
                                                    {assignedPromotionsList.promotionConfigurationId}
                                                    <Tooltip title="Copy Text">
                                                        <CopyIcon
                                                            fontSize="small"
                                                            onClick={() =>
                                                                copyPromoId(
                                                                    assignedPromotionsList.promotionConfigurationId
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableMiddleCellDisplayData"
                                            >
                                                {assignedPromotionsList.market}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableMiddleCellDisplayData"
                                            >
                                                {assignedPromotionsList.startDate}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableMiddleCellDisplayData"
                                            >
                                                {assignedPromotionsList.endDate}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableMiddleCellDisplayData"
                                            >
                                                {assignedPromotionsList.mechanic}
                                            </TableCell>
                                            {!prizeLoading && (
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        prizesAvailable
                                                            ? 'tableFirstCellDisplayDataGreen'
                                                            : 'tableFirstCellDisplayDataRed'
                                                    }
                                                >
                                                    {prizesAvailable ? 'Yes' : 'No'}
                                                </TableCell>
                                            )}
                                            {prizeLoading && (
                                                <TableCell
                                                    align="left"
                                                    className={'tableMiddleCellDisplayData'}
                                                >
                                                    <CircularProgress size={20} color="inherit" />
                                                </TableCell>
                                            )}
                                            <TableCell
                                                align="left"
                                                className="tableLastCellDisplayData"
                                            >
                                                <Button
                                                    onClick={toEditPromotion}
                                                    variant="outlined"
                                                    className={`buttonManageCreate ${listAssignedPromosProps.createDisabled ? 'disabled' : ''}`}
                                                    disabled={listAssignedPromosProps.createDisabled}
                                                >
                                                    Manage
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    )}
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                className="paginationCell"
                                rowsPerPageOptions={[5, 10, 25]}
                                count={assignedPromotionsList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}

export { ListAssignedPromotions };
