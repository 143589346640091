import awsconfig from '../../aws-exports';
import { Box } from '@mui/material';
import { InputComponent } from '../../components/inputComponents/InputComponent';
import { PrizeImgFileUpload } from '../inputComponents/FileUpload/PrizeFileUpload.component';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { useState } from 'react';
import { getIn } from 'formik';
import { defaultNotifState } from '../../constants/currency-constants';
import { defaultModalMetadataState } from '../../constants/prize-constants';
import { Notification } from '../../common/Notification/Notification';
import { ToolTipPlacement } from '../../types/componentTypes/tooltip';
import PrizeImageMetadataModal from '../modals/PrizeImageMetadataModal';
import useLanguageSetup from '../../pages/AddPrize/hooks/useLanguageSetupHook'
import { SwitchButton } from '../inputComponents/SwitchComponent';

interface PrizeLocalizationProps {
    formik: any;
    language: string;
    backstepDone?: boolean;
    isEdit?: boolean;
    languageIndex?: number;
    tabIndex: number;
    defaultLang: string;
    numberOfTabs: number;
    setLanguageIndex?: (value: number) => void;
}

function PrizeLocalization({
    formik,
    language,
    backstepDone,
    isEdit,
    languageIndex,
    tabIndex,
    defaultLang,
    numberOfTabs,
    setLanguageIndex,
}: PrizeLocalizationProps) {
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [modalMetadataState, setModalMetadataState] = useState(defaultModalMetadataState);
    const bucketName = awsconfig.aws_user_files_s3_bucket;
    const env = bucketName.split('-').pop();
    const envPathNaming = env === "prod" ? "global." : (env === "production" || env === "production-it") ? "" : `${env}.`;
    const prizeImagePath = `https://public.${envPathNaming}ngps.coca-cola.com/prizeImages/${formik.values.configuration_id}/${formik.values.prize_id}/`;
    useLanguageSetup(setLanguageIndex, language, formik.values, formik.setFieldValue)

    return (
        <Box className='prize-box-content'>
            <Notification
                notificationState={notificationState}
                setNotificationState={setNotificationState}
                messageVisibilityTime={2000}
            />
            <TooltipText
                dataTestId='prize-name-tooltip'
                styleName='prize-tooltip-text'
                text='Prize Name'
                textVariant='body1'
                tooltip="Make sure this prize name doesn't exist already under this promotion to avoid any potential confusion"
                iconSize='medium'
            />
            <InputComponent
                inputClassName='input-space'
                formik={formik}
                inputValue={getIn(formik.values, `name[${languageIndex}][${language}]`)}
                formikLabel={`name[${languageIndex}][${language}]`}
                placeholder='Up to 90 characters'
                errorPath={'name'}
            />
            {tabIndex > 1 && <SwitchButton
                formik={formik}
                formikValue={'tempParams.useDefaultImg'}
                label={'Use images uploaded for default language'}
                disableToggle={false}
            />}
            {(tabIndex <= 1 || !formik.values.tempParams.useDefaultImg) && <>
                <TooltipText
                    dataTestId='prize-image-tooltip'
                    styleName='prize-tooltip-text'
                    text='Prize Image'
                    textVariant='body1'
                    tooltip={'The file name will be used as image name. Please use meaningful file names. \
                Recommended image resolution: 567 x 567 px. Allowed image formats: PNG, JPG, SVG'}
                    iconSize='medium'
                    placement={ToolTipPlacement.RightEnd}
                />
                <PrizeImgFileUpload
                    backstepDone={backstepDone}
                    isEdit={isEdit}
                    cachedFiles={formik.values.tempParams.img_url?.[`${language}`]?.map((img: string) => ({ name: img })) || []}
                    uploadCallback={(files: Array<String>) => {
                        const imgUrls = []
                        for (const file of files) {
                            imgUrls.push(`${prizeImagePath}${file}`)
                        }
                        formik.setFieldValue('img_url', [...(getIn(formik.values, 'img_url') || []), ...imgUrls])
                        formik.setFieldValue(`tempParams.img_url`, {
                            ...formik.values.tempParams.img_url,
                            [language]: [...(getIn(formik.values, `tempParams.img_url[${language}]`) || []), ...imgUrls]
                        })
                    }}
                    deleteCallback={(file: string) => {
                        const updatedTempParamsImgUrl = formik.values.tempParams.img_url?.[`${language}`]?.filter((img: string) => img !== file);
                        const updatedImgUrl = formik.values.img_url?.filter((img: string) => img !== file);
                        formik.setFieldValue(`tempParams.img_url.[${language}]`, updatedTempParamsImgUrl);
                        formik.setFieldValue('img_url', updatedImgUrl);
                        if (language !== defaultLang && !updatedTempParamsImgUrl.length && numberOfTabs === 3) {
                            formik.setFieldValue('tempParams.useDefaultImg', true)
                        }
                    }}
                    placeholder={'No file chosen'}
                    bucket='public'
                    filePath={`prizeImages/${formik.values.configuration_id}/${formik.values.prize_id}`}
                    maxFiles={5}
                    usePublicDomain={true}
                    customAllowedFileTypes={['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml']}
                    canEdit={true}
                    setOpenEditModal={setModalMetadataState}
                    setNotificationState={setNotificationState}
                    progressKey={language}
                    filesProcessed={getIn(formik.values, `tempParams.img_url`)}
                    errorText={(getIn(formik.touched, 'img_url') && getIn(formik.errors, 'img_url')) || (getIn(formik.errors, `tempParams.img_url[${defaultLang}]`)) || (getIn(formik.errors, `tempParams.img_url`))}
                />
            </>}
            <PrizeImageMetadataModal
                props={{
                    showModal: modalMetadataState.open,
                    url: modalMetadataState.url,
                    setModalState: setModalMetadataState,
                    formik,
                    language,
                }}
            />
            <InputComponent
                minRows={3}
                dataTestId='long-description-prize'
                inputClassName='input-space'
                inputPropsClass='prize-localization-input'
                formik={formik}
                inputValue={getIn(formik.values, `desc[${languageIndex}][${language}]`)}
                formikLabel={`desc[${languageIndex}][${language}]`}
                placeholder='Up to 1000 characters'
                title='Long Description'
                titleVariant='body1'
                errorPath='desc'
                multiline={true}
            />
            <InputComponent
                minRows={3}
                inputClassName='input-space'
                inputPropsClass='prize-localization-input'
                formik={formik}
                inputValue={getIn(
                    formik.values,
                    `short_desc[${languageIndex}][${language}]`
                )}
                formikLabel={`short_desc[${languageIndex}][${language}]`}
                placeholder='Up to 250 characters'
                title='Short Description'
                titleVariant='body1'
                errorPath='short_desc'
                multiline={true}
            />
            <TooltipText
                dataTestId='prize-redemption-instructions-tooltip'
                styleName='prize-tooltip-text'
                text='Prize Redemption Instructions'
                textVariant='body1'
                tooltip='Enter the terms and conditions on where and by when the winner can redeem the prize, if a specific voucher is required, etc.'
                iconSize='medium'
            />
            <InputComponent
                minRows={3}
                inputClassName='input-space'
                inputPropsClass='prize-localization-input'
                formik={formik}
                inputValue={getIn(
                    formik.values,
                    `redeem_desc[${languageIndex}][${language}]`
                )}
                formikLabel={`redeem_desc[${languageIndex}][${language}]`}
                placeholder='Up to 1000 characters'
                errorPath='redeem_desc'
                multiline={true}
            />
            <TooltipText
                dataTestId='redemption-url-tooltip'
                styleName='prize-tooltip-text'
                text='Redemption URL'
                textVariant='body1'
                tooltip='Is there a URL consumer should go to redeem the voucher or an email address to contact?'
                iconSize='medium'
            />
            <InputComponent
                inputClassName='input-space'
                formik={formik}
                inputValue={getIn(formik.values, 'redemption_link')}
                formikLabel={'redemption_link'}
                placeholder='https://example.com or example@mail.com'
            />
        </Box>
    );
}

export { PrizeLocalization };
