import * as yup from 'yup';

function campaignValidationSchema() {
return yup.object({
    promotion_name: yup
        .string()
        .max(90,'The maximum length of the campaign name is 90 characters')
        .required('Campaign name cannot be empty'),
    promotion_fullName: yup
        .string()
        .max(250,'The maximum length of the campaign full name is 250 characters')
        .required('Campaign full name cannot be empty'),
    promotion_owner: yup
        .string()
        .email('Invalid email format'),
    digital_experience: yup
        .array()
        .min(1, "At least one experience must be selected")
        .required('Experiences are required'),
    promotion_market: yup
        .array()
        .min(1, "At least one market must be selected"),
    promotion_start_utc: yup
        .date().transform(function (castValue, originalValue) {
            return this.isType(castValue) ? castValue : new Date(originalValue)
          })
        .required('Start date is required'),
    promotion_end_utc: yup
        .date().transform(function (castValue, originalValue) {
            return this.isType(castValue) ? castValue : new Date(originalValue)
          })
        .required('End date is required')
        .when(
            'promotion_start_utc',
            (promotion_start_utc: Date, schema: any) => (promotion_start_utc && schema.min(promotion_start_utc, "End date should be after the start date!")),
          ),
    promotion_dateRange: yup
        .array()
        .required('Date range selection is required')
  });
}

function addNewExperienceValidationSchema() {
    return yup.object({
    option_name: yup
        .string()
        .max(90,'Option name should be bellow 90 characters length')
        .required('Field is required'),
    })
}


export { campaignValidationSchema, addNewExperienceValidationSchema }
