// custom
import { MIN_AGE, MAX_AGE } from '../constants/AdditionalDetailsVars';

// types
import type {
    ViewProps,
    EventHandlers,
    CheckBoxHandlerParams,
    MinAgeStateUpdate,
    CheckerLambdaObjectMap,
    DefaultChangeEvent,
    InputHandlerParams,
    VisibleFromDateHandlerParams,
    AllowedValueTypes,
    AdditionalDetailsState,
    GetAddCaptchaCheckBoxComponentProps,
    AddCaptchaCheckBoxProps,
    GetListPrizesCheckBoxComponentProps,
    ListPrizesCheckBoxProps,
    GetTeaserComponentProps,
    TeaserComponentProps,
    GetWVComponentProps,
    InputTextFieldComponentProps,
    GetMinAgeComponentProps,
    MinAgeComponentProps,
    GetInterruptedFlowComponentProps,
    InterruptedFlowComponentProps,
    GetAdditionalInfoxComponentProps,
    AdditionalInfoComponentProps,
    CustomInfoHandlerParams,
} from '../types/AdditionalDetailsTypes';

export function handlers<T>(props: ViewProps<T>): EventHandlers {
    const { updateStateValues, setPageErrors, pageErrors } = props;

    function checkBoxHandler(params: CheckBoxHandlerParams) {
        const { event, componentConfigPropKeys } = params;
        const checked = event.target.checked;
        try {
            updateStateValues({
                configPropPath: componentConfigPropKeys,
                value: checked,
                actionType: checked ? 'add' : 'remove',
            });

            checkAndClearFieldErrors({ name: componentConfigPropKeys, value: checked });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    function minAgeCheckBoxHandler(params: CheckBoxHandlerParams) {
        try {
            const { checked } = params.event.target;
            const checkboxPropKeys = JSON.parse(params.componentConfigPropKeys);

            const stateUpdates: MinAgeStateUpdate[] = checkboxPropKeys.map(
                (componentConfigPropKey: string | CheckerLambdaObjectMap) => {
                    if (componentConfigPropKey === 'formState.minAgeEnabled') {
                        return {
                            configPropPath: componentConfigPropKey,
                            value: checked,
                            actionType: checked ? 'add' : 'remove',
                        };
                    }

                    if (typeof componentConfigPropKey === 'object') {
                        const { key, value } = componentConfigPropKey;
                        return {
                            value,
                            configPropPath: key,
                            action: checked ? 'add' : 'delete',
                            actionType: 'modify-checkers',
                        };
                    }

                    return {
                        configPropPath: componentConfigPropKey,
                        value: checked ? props.formState.minAge : null,
                        actionType: checked ? 'add' : 'remove',
                    };
                }
            );

            updateStateValues({
                actionType: 'minAge',
                stateUpdates,
            });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    function radioButtonHandler(event: DefaultChangeEvent) {
        try {
            updateStateValues({
                configPropPath: event.target.name,
                value: event.target.value,
                actionType: 'modify',
            });

            checkAndClearFieldErrors({ name: event.target.name, value: event.target.value });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    function inputHandler({ event, componentConfigPropKeys }: InputHandlerParams) {
        try {
            updateStateValues({
                configPropPath: event.target.name,
                value: event.target.value,
                actionType: 'modify',
            });

            return checkAndClearFieldErrors({ name: componentConfigPropKeys, value: event.target.value });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    function customInfoHandler({customInfo, componentConfigPropKeys}: CustomInfoHandlerParams) {
        try {
            const convertedInfo = {};
            customInfo.map(({ key, value }) => (convertedInfo[key] = value));

            // update additionalInformation config state
            updateStateValues({
                configPropPath: componentConfigPropKeys,
                value: {
                    ...convertedInfo,
                    ...props.formState.predefinedAdditionalInfo,
                },
                actionType: 'modify' as const,
            });

            // update customInfo formState
            updateStateValues({
                configPropPath: 'formState.customAdditionalInfo',
                value: customInfo,
                actionType: 'modify' as const,
            });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    function visibleFromDateHandler(params: VisibleFromDateHandlerParams) {
        try {
            updateStateValues({
                configPropPath: params.componentConfigPropKeys,
                value: params.date,
                actionType: 'modify',
            });

            checkAndClearFieldErrors({ name: params.componentConfigPropKeys, value: params.date });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    function checkAndClearFieldErrors({ value, name }: { value: AllowedValueTypes; name: string }) {
        try {
            const pageError = pageErrors.has(name);

            if (!pageError && value) return;

            setPageErrors((prev) => {
                const newMap = new Map(prev);
                newMap.delete(name);

                return newMap;
            });
        } catch (error) {
            console.error(error, 'error');
        }
    }

    return { checkBoxHandler, radioButtonHandler, inputHandler, visibleFromDateHandler, minAgeCheckBoxHandler, customInfoHandler };
}

export function getAddCaptchaCheckBoxProps<T extends AdditionalDetailsState>(
    props: GetAddCaptchaCheckBoxComponentProps<T>
): AddCaptchaCheckBoxProps<T> {
    const {
        formState,
        checkBoxHandler,
        mechanic,
        formattedMechanic,
        componentConfigPropKeys,
        labelElement,
        ...otherProps
    } = props;

    const baseTestId = `additional-details-${formattedMechanic}-captcha-secret`;

    return {
        formState,
        containerDataTestId: `${baseTestId}-container`,
        textFieldProps: {
            inputValue: formState.captchaSecret,
            label: 'Enter Captcha Secret',
            fieldName: `${baseTestId}-input-id`,
            dataTestId: `${baseTestId}-input`,
            titleVariant: 'body1' as const,
            focusOnElement: true,
            required: true,
            inputFieldType: 'text',
            customErrorMessage: 'If option selected, captcha secret has to be provided.',
            componentConfigPropKeys: componentConfigPropKeys.textField,
            inputProps: {
                'aria-label': `${baseTestId}-input-id`,
                'data-testid': `${baseTestId}-input`,
            },
            ...otherProps,
        },
        checkBoxProps: {
            fieldName: `${baseTestId}-checkbox-id`,
            dataTestId: `${baseTestId}-checkbox`,
            isChecked: formState.captchaSecretEnabled,
            componentConfigPropKeys: componentConfigPropKeys.checkBox,
            labelElement,
            checkBoxHandler,
        },
    };
}

export function getAdditionalInfoComponentProps<T extends AdditionalDetailsState>(
    props: GetAdditionalInfoxComponentProps<T>
): AdditionalInfoComponentProps<T> {
    const {
        formState,
        checkBoxHandler,
        mechanic,
        formattedMechanic,
        componentConfigPropKeys,
        labelElement,
        customInfoHandler,
        ...otherProps
    } = props;

    const baseTestId = `additional-details-${formattedMechanic}-additional-info`;

    return {
        formState,
        containerDataTestId: `${baseTestId}-container`,
        textFieldProps: {
            multiline: true,
            fieldName: `${baseTestId}-input-id`,
            titleVariant: 'body1' as const,
            focusOnElement: true,
            required: true,
            inputFieldType: 'text',
            customErrorMessage: 'Please fill in this field.',
            componentConfigPropKeys: componentConfigPropKeys.textField,
            inputProps: {
                'aria-label': `${baseTestId}-input-id`
            },
            customInfoHandler,
            ...otherProps,
        },
        checkBoxProps: {
            fieldName: `${baseTestId}-checkbox-id`,
            dataTestId: `${baseTestId}-checkbox`,
            isChecked: formState.additionalInfoEnabled,
            componentConfigPropKeys: componentConfigPropKeys.checkBox,
            labelElement,
            checkBoxHandler,
        },
    };
}

export function getListPrizesCheckBoxProps<T extends AdditionalDetailsState>(
    props: GetListPrizesCheckBoxComponentProps<T>
): ListPrizesCheckBoxProps<T> {
    const { formattedMechanic, componentConfigPropKeys, radioButtonHandler, formState, checkBoxHandler, labelElement } =
        props;

    const baseTestId = `additional-details-${formattedMechanic}-list-prizes`;

    return {
        radioGroupProps: {
            fieldName: `${baseTestId}-radio-group-id`,
            dataTestId: `${baseTestId}-radio-group`,
            value: formState.priorityOrder,
            radioButtonHandler: radioButtonHandler,
            componentConfigPropKeys: componentConfigPropKeys.radioGroup,
            radioButtonOptions: [
                { option: 'ASC', label: 'Ascending', dataTestId: `${baseTestId}-radio-asc` },
                { option: 'DESC', label: 'Descending', dataTestId: `${baseTestId}-radio-desc` },
            ],
            title: ' Prize List Priority Order',
        },
        checkBoxProps: {
            fieldName: `${baseTestId}-checkbox-id`,
            dataTestId: `${baseTestId}-checkbox`,
            isChecked: !!formState.listPrizes,
            checkBoxHandler: checkBoxHandler,
            componentConfigPropKeys: componentConfigPropKeys.checkBox,
            labelElement: labelElement,
        },
        containerDataTestId: `${baseTestId}-container`,
    };
}

export function getTeaserComponentProps<T extends AdditionalDetailsState>(
    props: GetTeaserComponentProps<T>
): TeaserComponentProps<T> {
    const {
        checkBoxHandler,
        formState,
        formattedMechanic,
        visibleFromDateHandler,
        componentConfigPropKeys,
        labelElement,
        ...otherProps
    } = props;

    const baseTestId = `additional-details-${formattedMechanic}-promo-teaser`;

    return {
        checkBoxProps: {
            fieldName: `${baseTestId}-checkbox-id`,
            dataTestId: `${baseTestId}-checkbox`,
            isChecked: formState.promotionTeaserEnabled,
            componentConfigPropKeys: componentConfigPropKeys.checkBox,
            checkBoxHandler,
            labelElement,
        },
        datePickerProps: {
            fieldName: `${baseTestId}-date-id`,
            dataTestId: `${baseTestId}-date`,
            value: formState.visibleFromDate,
            focusOnElement: true,
            componentConfigPropKeys: componentConfigPropKeys.datePicker,
            visibleFromDateHandler,
        },
        formState,
        ...otherProps,
    };
}

export function getWVComponentProps(props: GetWVComponentProps): InputTextFieldComponentProps {
    const { wv_url, componentConfigPropKeys, formattedMechanic, ...otherProps } = props;

    const baseTestId = `additional-details-${formattedMechanic}-webview-url`;

    return {
        inputValue: wv_url,
        fieldName: `${baseTestId}-id`,
        dataTestId: `${baseTestId}-input`,
        titleVariant: 'body1' as const,
        title: 'WebView URL',
        focusOnElement: false,
        required: false,
        inputFieldType: 'url',
        placeholderText: 'https://example.com',
        componentConfigPropKeys,
        ...otherProps,
    };
}

export function getMinAgeComponentProps<T extends AdditionalDetailsState>(
    props: GetMinAgeComponentProps<T>
): MinAgeComponentProps<T> {
    const { formState, formattedMechanic, componentConfigPropKeys, pageErrors, setPageErrors } = props;

    const baseTestId = `additional-details-${formattedMechanic}-min-age`;
    const minAge = typeof formState.minAge === 'number' ? formState.minAge : parseInt(formState.minAge) || 0;

    return {
        formState,
        pageErrors,
        setPageErrors,
        containerDataTestId: `${baseTestId}-container`,
        textFieldProps: {
            label: 'Minimum Age',
            inputValue: minAge,
            fieldName: `${baseTestId}-input-id`,
            dataTestId: `${baseTestId}-input`,
            titleVariant: 'body1' as const,
            inputProps: {
                min: MIN_AGE,
                max: MAX_AGE,
                style: { height: '20px', textAlign: 'center' },
            },
            required: true,
            focusOnElement: true,
            inputFieldType: 'Number',
            customErrorMessage: `Age limit is between ${MIN_AGE} and ${MAX_AGE}`,
            componentConfigPropKeys: componentConfigPropKeys.textField,
            textFieldStyles: {
                width: '200px',
                'input::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                'input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
            },
            inputHandler: props.setFormState,
        },
        checkBoxProps: {
            fieldName: `${baseTestId}-checkbox-id`,
            dataTestId: `${baseTestId}-checkbox`,
            isChecked: formState.minAgeEnabled,
            componentConfigPropKeys: componentConfigPropKeys.checkBox,
            labelElement: props.labelElement,
            checkBoxHandler: props.checkBoxHandler,
        },
    };
}

export function getInterruptedFlowComponentProps<T extends AdditionalDetailsState>(
    props: GetInterruptedFlowComponentProps<T>
): InterruptedFlowComponentProps<T> {
    const { checkBoxHandler, formState, componentConfigPropKeys, labelElement, formattedMechanic, ...otherProps } =
        props;

    const baseTestId = `additional-details-${formattedMechanic}-interrupted-flow`;

    return {
        formState,
        containerDataTestId: `${baseTestId}-container`,
        textFieldProps: {
            inputHandler: props.setFormState,
            label: 'Maximum vouchers to accept',
            fieldName: `${baseTestId}-input-id`,
            dataTestId: `${baseTestId}-input`,
            titleVariant: 'body1' as const,
            inputProps: {
                min: 1,
                max: 100,
                style: { height: '20px', textAlign: 'center' },
            },
            placeholderText: 'Enter number of vouchers',
            textFieldStyles: {
                width: '200px',
                'input::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                'input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
            },
            required: true,
            focusOnElement: true,
            inputFieldType: 'Number',
            componentConfigPropKeys: componentConfigPropKeys.textField,
            ...otherProps,
        },
        checkBoxProps: {
            fieldName: `${baseTestId}-checkbox-id`,
            dataTestId: `${baseTestId}-checkbox`,
            isChecked: formState.useStatusReserved,
            componentConfigPropKeys: componentConfigPropKeys.checkBox,
            labelElement,
            checkBoxHandler,
        },
    };
}
