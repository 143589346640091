import Checkbox from '@mui/material/Checkbox';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { useField } from 'formik';
import './CheckboxComponent.css';
import { StyledEngineProvider } from '@mui/material/styles';

interface DefineFuncProps {
    fieldName: string;
    label: string | JSX.Element;
    labelVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body1' | 'body2' | 'caption';
    customAction?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    customOnChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabledCondition?: boolean;
    customBoxClass?: string;
    testingId?: string;
}

function CheckboxComponent({
    fieldName,
    label,
    labelVariant,
    disabledCondition,
    customBoxClass,
    testingId,
    customAction,
    customOnChangeHandler
}: DefineFuncProps) {
    const [field, , helpers] = useField(fieldName);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!customOnChangeHandler) {
            helpers.setValue(!field.value);
        } else {
            customOnChangeHandler(event);
        }

        if (customAction) {
            customAction(event);
        }
    };

    return (
        <StyledEngineProvider injectFirst>
            <Box className={customBoxClass ? customBoxClass : ''}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className='checkbox-class'
                            data-testid={testingId ? testingId : 'component-checkbox'}
                            name={fieldName}
                            checked={!!field.value}
                            disabled={disabledCondition}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': testingId }}
                        />
                    }
                    label={
                        typeof label === 'string' ? (
                            <Typography
                                variant={labelVariant ? labelVariant : 'body1'}
                                className={disabledCondition ? 'half-opacity' : 'full-opacity'}>
                                {label}
                            </Typography>
                        ) : (
                            label
                        )
                    }
                />
            </Box>
        </StyledEngineProvider>
    );
}
export { CheckboxComponent };
